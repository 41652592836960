import * as React from "react"
import TeamScroller from "./TeamScroller"
import { Fade } from "react-awesome-reveal"

const Team = ({ data }) => {
  const heading = data.heading
  const body = data.body
  const teamMembers = data.teamMembers

  return (
    <div className="pt-20 bg-light-sand">
      <Fade triggerOnce duration={750} fraction={0.5}>
        <div className="grid grid-cols-8 gap-gs text-dark-grey px-ogs mb-20">
          <div className="col-span-5">
            {heading && (
              <h2 className="text-7xlb lg:text-8xl tracking-tightest leading-tightest font-fletcha">
                {heading}
              </h2>
            )}
          </div>
          <div className="hidden lg:block col-span-3 border-l border-dark-grey -ml-gs-1/2 pl-gs-1/2">
            {body && (
              <div
                className="max-w-sm font-tomato text-2xs leading-relaxed"
                dangerouslySetInnerHTML={{ __html: body }}
              />
            )}
          </div>
        </div>
      </Fade>
      <TeamScroller data={teamMembers} />
    </div>
  )
}

export default Team
