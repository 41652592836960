import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Fade } from "react-awesome-reveal"

const Product = ({ data }) => {
  const heading = data.heading
  const body = data.body
  const figureHeading = data.figureHeading
  const figureCaption = data.figureCaption
  const image = data.image
  const imageSecondary = data.imageSecondary

  let imageData = {}
  if (image && image.length) {
    imageData = {
      image: getImage(image[0].localFile),
      alt: image[0]?.localFile?.name || "",
    }
  }

  let imageSecondaryData = {}
  if (imageSecondary && imageSecondary.length) {
    imageSecondaryData = {
      image: getImage(imageSecondary[0].localFile),
      alt: imageSecondary[0]?.localFile?.name || "",
    }
  }

  return (
    <div className="bg-green text-electric-blue pb-24 xl:py-16">
      {imageData && (
        <div className="relative grid grid-cols-8 gap-gs xl:hidden mb-16">
          <div className="col-start-2 col-span-6 md:col-start-3 md:col-span-4 img-div mx-4">
            <Fade
              triggerOnce
              duration={500}
              fraction={0.5}
              className="relative z-10 w-full"
            >
              <GatsbyImage
                className="bg-green-support"
                objectFit="contain"
                image={imageData.image}
                alt={imageData.alt}
              />
            </Fade>
          </div>
        </div>
      )}
      <div className="grid grid-cols-8 xl:grid-rows-2 gap-x-gs px-ogs">
        {imageData && (
          <div className="hidden xl:block xl:col-span-2 xl:col-start-1 xl:row-start-2 -mr-gs-1/2">
            <Fade triggerOnce duration={750} fraction={0.25} delay={250}>
              <GatsbyImage
                className="bg-green-support"
                image={imageData.image}
                alt={imageData.alt}
              />
            </Fade>
          </div>
        )}

        <Fade
          triggerOnce
          duration={500}
          fraction={0.25}
          className="col-span-8 xl:col-start-3 xl:col-span-3 mb-20 xl:mb-0 xl:self-end"
        >
          <div>
            {heading && (
              <div
                className="text-7xlb xl:text-8xl font-fletcha tracking-tightest leading-16 xl:leading-20 max-w-xs sm:max-w-sm xl:max-w-md rte"
                dangerouslySetInnerHTML={{ __html: heading }}
              ></div>
            )}
            {body && (
              <div
                className="font-tomato text-sm tracking-wide leading-5 mt-10 max-w-sm"
                dangerouslySetInnerHTML={{ __html: body }}
              />
            )}
          </div>
        </Fade>

        <div className="col-span-8 md:col-start-2 md:col-span-6 lg:col-start-3 lg:col-span-4 xl:col-start-6 xl:col-span-3 xl:row-span-2 xl:flex xl:flex-col xl:justify-between">
          <Fade triggerOnce duration={750} fraction={0.25} delay={250}>
            {imageSecondaryData && (
              <GatsbyImage
                className="bg-green-support"
                image={imageSecondaryData.image}
                alt={imageSecondaryData.alt}
              />
            )}
            <div className="grid grid-cols-8 xl:grid-cols-3 gap-gs leading-5">
              {figureHeading && (
                <h4 className="col-span-3 xl:col-span-1 uppercase text-2base">
                  {figureHeading}
                </h4>
              )}
              {figureCaption && (
                <div
                  className="col-start-4 col-span-5 xl:col-span-2 xl:col-start-2 text-sm tracking-wide mr-ogs xl:mr-0"
                  dangerouslySetInnerHTML={{ __html: figureCaption }}
                />
              )}
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default Product
