import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Fade } from "react-awesome-reveal"

import "./Careers.css"
import Button from "../button/Button"

const Careers = ({ data }) => {
  const heading = data.heading
  const image = data.image
  const externalLink = data.externalLink

  let imageData = {}
  if (image && image.length) {
    imageData = {
      image: getImage(image[0].localFile),
      alt: image[0]?.localFile?.name || "",
    }
  }

  return (
    <div className="pt-32 lg:pt-10 lg:pb-36 bg-sand px-ogs grid grid-cols-8 gap-gs">
      <div className="col-start-1 col-end-9 lg:col-end-6 text-dark-grey relative">
        <Fade
          triggerOnce
          duration={500}
          fraction={0.25}
          className="col-start-1 col-end-2"
        >
          <h2 className="uppercase text-sm leading-5 font-tomato mb-4">
            Careers
          </h2>
          {heading && (
            <div
              className="text-7xlb xl:text-8xl font-fletcha tracking-tightest leading-16 lg:leading-20 max-w-2xl rte"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
          )}
        </Fade>
        {externalLink && (
          <div className="col-start-1 col-end-8 mt-12 lg:mt-0 max-w-300 w-full lg:w-fit lg:absolute bottom-0 left-0 xl:-ml-9">
            <Fade triggerOnce duration={750} fraction={0.25} delay={250}>
              <Button
                href={externalLink}
                text="Apply Now"
                colour="dark-grey"
                font="tomato"
              />
            </Fade>
          </div>
        )}
      </div>

      {imageData.image && (
        <div className="col-start-1 lg:col-start-6 col-end-9 pt-4 mt-12 lg:mt-0">
          <Fade triggerOnce duration={750} fraction={0.25} delay={250}>
            <GatsbyImage image={imageData.image} alt={imageData.alt} />
          </Fade>
        </div>
      )}
    </div>
  )
}

export default Careers
