import React from "react"

const IconArrow = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 56 8">
      <path d="M55.3536 4.35355C55.5488 4.15829 55.5488 3.84171 55.3536 3.64645L52.1716 0.464466C51.9763 0.269204 51.6597 0.269204 51.4645 0.464466C51.2692 0.659728 51.2692 0.976311 51.4645 1.17157L54.2929 4L51.4645 6.82843C51.2692 7.02369 51.2692 7.34027 51.4645 7.53553C51.6597 7.7308 51.9763 7.7308 52.1716 7.53553L55.3536 4.35355ZM0 4.5H55V3.5H0V4.5Z" />
    </svg>
  )
}

export default IconArrow
