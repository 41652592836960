import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { CSSTransition } from "react-transition-group"
import { Fade } from "react-awesome-reveal"
import TeamOverlay from "./TeamOverlay"
import Portal from "../portal/Portal"

import "./TeamScroller.css"

const TeamScroller = ({ data }) => {
  const [showOverlay, setShowOverlay] = useState(false)
  const [overlayData, setOverlayData] = useState(null)

  const handleClick = item => {
    setShowOverlay(true)
    setOverlayData(item)
  }

  return (
    <div>
      <Portal>
        <CSSTransition
          in={showOverlay}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <TeamOverlay
            data={overlayData}
            onClose={() => setShowOverlay(false)}
          />
        </CSSTransition>
      </Portal>
      <Fade triggerOnce duration={750} fraction={0.5} delay={250}>
        <div className="TeamScroller">
          <div className="TeamScroller__main">
            {[...data].map((item, index) => {
              const image = item.image

              let sliderClass = "TeamScroller__slide"
              if (index + 1 === data.length) {
                sliderClass = "TeamScroller__slide TeamScroller__slide--last"
              }
              if (index === 0) {
                sliderClass = "TeamScroller__slide TeamScroller__slide--first"
              }

              let imageData = {}
              if (image.length) {
                imageData = {
                  image: getImage(image[0].localFile),
                  alt: item.heading,
                }
              }
              return (
                <div key={index} className={sliderClass}>
                  <button
                    className="TeamScroller__slide-button"
                    onClick={() => handleClick(item)}
                  >
                    {imageData.image && (
                      <div className="TeamScroller__slide-image mb-8">
                        <GatsbyImage
                          image={imageData.image}
                          alt={imageData.alt}
                          loading="eager"
                        />
                        <div className="TeamScroller__slide-image-overlay"></div>
                      </div>
                    )}
                    {item.title && (
                      <h3 className="text-3xl font-fletcha tracking-tight leading-tightest">
                        {item.title}
                      </h3>
                    )}
                    <div className="h-4 text-3xs font-fletcha leading-tightest">
                      {item.subheading && item.subheading}
                    </div>
                    {item.heading && (
                      <h4 className="font-tomato mb-5">{item.heading}</h4>
                    )}
                    <div className="TeamScroller__slide-cta">
                      <div className="TeamScroller__slide-cta-icon">
                        <div className="TeamScroller__slide-cta-icon-a"></div>
                        <svg
                          className="TeamScroller__slide-cta-icon-b"
                          viewBox="0 0 200 200"
                        >
                          <circle
                            className="TeamScroller__slide-cta-icon-b-el"
                            cx="100"
                            cy="100"
                            r="98"
                            strokeWidth="4"
                            fillOpacity="0"
                          />
                        </svg>
                      </div>
                      <div className="TeamScroller__slide-cta-text">
                        Read More
                      </div>
                    </div>
                  </button>
                </div>
              )
            })}
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default TeamScroller
