import React, { useEffect } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import "./TeamOverlay.css"

const TeamOverlay = ({ data, onClose }) => {
  const image = data.image

  let imageData = {}
  if (image.length) {
    imageData = {
      image: getImage(image[0].localFile),
      alt: data.heading,
    }
  }

  useEffect(() => {
    const onKeydown = event => {
      if (event.which === 27) onClose()
    }
    document.body.classList.add("overflow-hidden")
    document.addEventListener("keydown", onKeydown)
    return () => {
      document.body.classList.remove("overflow-hidden")
      document.removeEventListener("keydown", onKeydown)
    }
  }, [onClose])

  return (
    <div className="fixed inset-0 px-ogs py-20 bg-teal-support-40 overflow-y-auto z-50">
      <div className="absolute top-6 right-ogs">
        <button className="TeamOverlay__button" onClick={() => onClose()}>
          Close
          <div className="TeamOverlay__button-icon-wrapper">
            <div className="TeamOverlay__button-icon"></div>
            <svg className="TeamOverlay__button-svg" viewBox="0 0 200 200">
              <circle
                className="TeamOverlay__button-svg-cricle"
                cx="100"
                cy="100"
                r="96"
                strokeWidth="4"
                fillOpacity="0"
              />
            </svg>
          </div>
        </button>
      </div>

      <div className="mb-20">
        <h2 className="font-fletcha text-8xl md:text-10vw leading-tightest">
          {data.title}
        </h2>
      </div>

      <div className="grid grid-cols-8 gap-x-gs gap-y-14 mb-10 md:mb-16">
        {imageData.image && (
          <div className="col-span-8 md:col-span-3 lg:col-span-2">
            <div className="w-3/5 mx-auto">
              <GatsbyImage
                image={imageData.image}
                alt={imageData.alt}
                loading="eager"
              />
            </div>
          </div>
        )}

        <div className="col-span-8 md:col-span-5 xl:col-span-4 space-y-8 md:space-y-12">
          {data.lead && (
            <div
              className="TeamOverlay__lead font-fletcha text-xl"
              dangerouslySetInnerHTML={{ __html: data.lead }}
            />
          )}
          {data.body && (
            <div
              className="font-tomato"
              dangerouslySetInnerHTML={{ __html: data.body }}
            />
          )}
        </div>
      </div>

      <div className="grid grid-cols-8 gap-x-gs gap-y-4">
        {data.heading && (
          <div className="col-span-8 md:col-span-3 lg:col-span-2">
            <h4 className="text-sm">{data.heading}</h4>
          </div>
        )}
      </div>
    </div>
  )
}

export default TeamOverlay
