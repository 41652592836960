import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Fade } from "react-awesome-reveal"

import "./Intro.css"

const Intro = ({ data }) => {
  const heading = data.heading
  const body = data.body
  const image = data.image

  let imageData = {}
  if (image && image.length) {
    imageData = {
      image: getImage(image[0].localFile),
      alt: image[0]?.localFile?.name || "",
    }
  }

  return (
    <div className="lg:grid lg:grid-cols-2 bg-blue relative lg:items-end">
      <div className="w-full px-ogs lg:pl-ogs">
        <Fade triggerOnce duration={750} fraction={0.25} delay={250}>
          <div className="max-w-745">
            {heading && (
              <div className="text-3xl tracking-tight lg:text-6xl text-spring-green leading-16 font-fletcha">
                <div
                  className="heading-indent w-fit pt-12 xl:pt-24 leading-10 lg:leading-15 rte lg:max-w-500"
                  dangerouslySetInnerHTML={{
                    __html: heading,
                  }}
                ></div>
              </div>
            )}
            {body && (
              <div className="border-l pl-6 border-spring-green lg:mb-12 xl:mb-24">
                <div
                  className="max-w-263 lg:max-w-325 text-sm text-spring-green font-tomato mt-16 xl:mt-36 tracking-wide rte"
                  dangerouslySetInnerHTML={{
                    __html: body,
                  }}
                ></div>
              </div>
            )}
          </div>
        </Fade>
      </div>
      {imageData.image && (
        <Fade triggerOnce duration={750} fraction={0.25} delay={500}>
          <div className="ml-ogs mt-20 lg:m-0">
            <GatsbyImage image={imageData.image} alt={imageData.alt} />
          </div>
        </Fade>
      )}
    </div>
  )
}

export default Intro
