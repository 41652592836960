import React, { useEffect, useRef } from "react"
import lottie from "lottie-web"
import { Fade } from "react-awesome-reveal"
import heroImage from "../../images/hero.png"

import "./Hero.css"
import Signal from "../../json/signal-hero.json"

const Hero = () => {
  const heroLottie = useRef(null)

  useEffect(() => {
    const heroAnimation = lottie.loadAnimation({
      container: heroLottie.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: Signal,
    })
    heroAnimation.play()

    const setHeroHeight = () => {
      const screenHeight = window.innerHeight
      const lottieHeight = heroLottie.current.offsetHeight
      const heroHeight = screenHeight + lottieHeight / 2
      document.documentElement.style.setProperty(
        "--heroHeight",
        `${heroHeight}px`
      )
    }

    setHeroHeight()
    window.addEventListener("resize", setHeroHeight)

    return () => {
      window.removeEventListener("resize", setHeroHeight)
      heroAnimation.destroy()
    }
  }, [])

  return (
    <div className="bg-blue min-h-screen">
      <div className="px-ogs absolute inset-x-0 top-shh z-10 pt-8">
        <Fade triggerOnce duration={750} delay={800}>
          <h1>
            <span className="hidden md:flex justify-between gap-x-5 font-fletcha text-6.75vw text-spring-green tracking-tightest leading-tight">
              <span>Excluding</span>
              <span className="font-fletcha-italic">cancer</span>
              <span>with</span>
              <span>confidence</span>
            </span>
            <span
              className="block md:hidden font-fletcha text-7xl sm:text-8xl
              text-spring-green tracking-tightest leading-tightest text-center"
            >
              Excluding <span className="font-fletcha-italic">cancer</span>{" "}
              <br /> with confidence
            </span>
          </h1>
        </Fade>
      </div>

      <Fade
        triggerOnce
        duration={500}
        delay={150}
        style={{ minHeight: "var(--heroHeight)" }}
      >
        <div className="relative -mt-shh" style={{ top: "100vh" }}>
          <div className="transform -translate-y-1/2 -mx-64 lg:mx-auto lg:w-4/5">
            <div className="relative w-full">
              <div className="Hero-lottie" ref={heroLottie}></div>
              <div className="Device-wrapper">
                <div className="w-1/2 sm:w-full mx-auto max-w-sm xl:max-w-480">
                  <img
                    className="w-full h-full object-contain"
                    src={heroImage}
                    alt="Zedsen device front view"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default Hero
