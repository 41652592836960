import React, { useEffect } from "react"
import { Element } from "react-scroll"
import { graphql } from "gatsby"

import Layout from "../components/layout/Layout"
import Section from "../components/section/Section"

import Hero from "../components/hero/Hero"
import Intro from "../components/intro/Intro"
import Features from "../components/features/Features"
import Product from "../components/product/Product"
import Careers from "../components/careers/Careers"
import Team from "../components/team/Team"
import Footer from "../components/footer/Footer"

const IndexPage = ({ data }) => {
  const global = data.craftGeneralGlobalSet
  const page = data.craftHomeHomeEntry
  const productPrimary = page.productPrimary[0]
  const productSecondary = page.productSecondary
  const productTertiary = page.productTertiary[0]
  const team = page.team[0]
  const careers = page.careers[0]

  useEffect(() => {
    document.documentElement.style.setProperty("--header-bg", "#274872")
    document.documentElement.style.setProperty("--header-text", "#DBF5B0")
  }, [])

  return (
    <Layout title={page.metaTitle} description={page.metaDescription}>
      <Element name="home" id="home">
        <Section theme={["#274872", "#DBF5B0"]}>
          <Hero />
        </Section>
      </Element>
      <Element name="mission" id="mission">
        <Section theme={["#274872", "#DBF5B0"]}>
          <Intro data={productPrimary} />
        </Section>
        <Section theme={["#1B4555", "#F8D990"]}>
          <Features data={productSecondary} />
        </Section>
        <Section theme={["#395A48", "#90DEFF"]}>
          <Product data={productTertiary} headerLink={global.headerLink} />
        </Section>
      </Element>
      <Element name="team" id="team">
        <Section theme={["#F8F6F2", "#323137"]}>
          <Team data={team} />
        </Section>
      </Element>
      <Section theme={["#DDD0BF", "#323137"]}>
        <Careers data={careers} />
      </Section>
      <Section theme={["#1B4555", "#F8F6F2"]}>
        <Footer />
      </Section>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query MyQuery {
    craftHomeHomeEntry {
      metaDescription
      metaTitle
      productPrimary {
        ... on Craft_productPrimary_item_BlockType {
          heading
          body
          image {
            ... on Craft_images_Asset {
              localFile {
                name
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      productSecondary {
        ... on Craft_productSecondary_items_BlockType {
          heading
          body
          image {
            id
            ... on Craft_images_Asset {
              url
              title
            }
            ... on Craft_icons_Asset {
              url
              title
            }
          }
        }
      }
      productTertiary {
        ... on Craft_productTertiary_item_BlockType {
          heading
          body
          image {
            ... on Craft_images_Asset {
              localFile {
                name
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
            }
          }
          imageSecondary {
            ... on Craft_images_Asset {
              localFile {
                name
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
            }
          }
          figureCaption
          figureHeading
        }
      }
      team {
        ... on Craft_team_item_BlockType {
          heading
          body
          teamMembers {
            ... on Craft_people_people_Entry {
              title
              heading
              subheading
              image {
                ... on Craft_images_Asset {
                  localFile {
                    name
                    childImageSharp {
                      gatsbyImageData(
                        layout: FIXED
                        aspectRatio: 0.75
                        width: 250
                        quality: 90
                        placeholder: NONE
                      )
                    }
                  }
                }
              }
              lead
              body
            }
          }
        }
      }
      careers {
        ... on Craft_careers_item_BlockType {
          heading
          externalLink
          image {
            ... on Craft_images_Asset {
              localFile {
                name
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
            }
          }
        }
      }
    }
    craftGeneralGlobalSet {
      headerLink {
        text
        element {
          ... on Craft_register_register_Entry {
            slug
          }
          ... on Craft_pages_pages_Entry {
            slug
          }
        }
      }
    }
  }
`
