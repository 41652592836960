import * as React from "react"
import { Link } from "gatsby"

import IconArrow from "../svg/IconArrow"
import "./Button.css"

const Button = ({ href, to, text, colour, font, hideMobileArrow }) => {
  let buttonColour

  if (colour === "warm-yellow") {
    buttonColour = "Button--warm-yellow"
  } else if (colour === "dark-grey") {
    buttonColour = "Button--dark-grey"
  } else {
    buttonColour = "Button--electric-blue"
  }

  if (href)
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className={`Button ${buttonColour} ${
          font === "tomato" ? "font-tomato" : "font-fletcha"
        } ${hideMobileArrow ? "" : "w-full sm:w-auto"}`}
      >
        <span
          className={`px-9 inline-flex items-center w-full h-full space-x-6 xl:space-x-16 ${
            hideMobileArrow
              ? "justify-center md:justify-between"
              : "justify-between"
          }`}
        >
          <span>{text}</span>
          <span
            className={
              hideMobileArrow
                ? "hidden md:inline md:w-12 md:md:w-16"
                : "w-12 md:w-16"
            }
          >
            <IconArrow />
          </span>
        </span>
        <svg className="Button__border" viewBox="0 0 477 68" fill="none">
          <path className="Button__border-primary" d="M37,67.6l406-0.1" />
          <path
            className="Button__border-secondary"
            pathLength="1000"
            d="M37,67.5h-3c-18.5,0-33.5-15-33.5-33.5l0,0c0-18.5,15-33.5,33.5-33.5h409c18.5,0,33.5,15,33.5,33.5l0,0 c0,18.5-15,33.5-33.5,33.5"
          />
        </svg>
      </a>
    )
  else
    return (
      <Link
        to={`/${to}`}
        className={`Button ${buttonColour} ${
          font === "tomato" ? "font-tomato" : "font-fletcha"
        } ${hideMobileArrow ? "" : "w-full sm:w-auto"}`}
      >
        <span
          className={`px-9 inline-flex items-center w-full h-full space-x-6 xl:space-x-16 ${
            hideMobileArrow
              ? "justify-center md:justify-between"
              : "justify-between"
          }`}
        >
          <span>{text}</span>
          <span
            className={
              hideMobileArrow
                ? "hidden md:inline md:w-12 md:md:w-16"
                : "w-12 md:w-16"
            }
          >
            <IconArrow />
          </span>
        </span>
        <svg className="Button__border" viewBox="0 0 477 68" fill="none">
          <path className="Button__border-primary" d="M37,67.6l406-0.1" />
          <path
            className="Button__border-secondary"
            pathLength="1000"
            d="M37,67.5h-3c-18.5,0-33.5-15-33.5-33.5l0,0c0-18.5,15-33.5,33.5-33.5h409c18.5,0,33.5,15,33.5,33.5l0,0 c0,18.5-15,33.5-33.5,33.5"
          />
        </svg>
      </Link>
    )
}

export default Button
