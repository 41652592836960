import React from "react"
import { Fade } from "react-awesome-reveal"

import "./Features.css"

const Features = ({ data }) => {
  if (!data) return null
  return (
    <div className="grid xl:grid-cols-3 bg-teal py-8 xl:py-20 text-center xl:text-left divide-sand	divide-y xl:divide-y-0 xl:divide-x px-ogs">
      {data.map((item, index) => {
        return (
          <div
            key={index}
            className="max-w-325 xl:max-w-none xl:w-full mx-auto pt-24 xl:py-20"
          >
            <Fade
              triggerOnce
              duration={500}
              fraction={0.25}
              delay={250 * (index + 1)}
            >
              <div className="xl:px-ogs">
                <h2 className="text-warm-yellow text-4xl md:text-6xl xl:text-6xlb font-fletcha tracking-tightest leading-tight md:leading-16">
                  {item.image[0] && (
                    <img
                      width="40"
                      height="48"
                      className="xl:inline mb-12 xl:mb-4 mx-auto xl:mr-8"
                      src={item.image[0].url}
                      alt={item.image[0].title}
                    />
                  )}
                  {item.heading &&
                    item.heading.split("\n").map((item, key) => {
                      return (
                        <React.Fragment key={key}>
                          {item}
                          <br className="hidden lg:block" />
                        </React.Fragment>
                      )
                    })}
                </h2>

                {item.body && (
                  <div
                    className="pb-32 xl:pb-0 max-w-270 xl:max-w-325 mx-auto xl:mr-auto xl:ml-0 text-warm-yellow font-tomato text-sm mt-12 rte"
                    dangerouslySetInnerHTML={{ __html: item.body }}
                  ></div>
                )}
              </div>
            </Fade>
          </div>
        )
      })}
    </div>
  )
}

export default Features
